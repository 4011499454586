import React from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import MediumIcon from '../assets/medium_logo.svg';
import OpenseaIcon from '../assets/opensea_logo.svg';
import LichessIcon from '../assets/lichess_logo.svg';

class Socials extends React.Component {

    render() {
        return (
            <div id="socials">
              <a className="socials-link" href="https://twitter.com/randombishop"><TwitterIcon /></a>
              <a className="socials-link" href="https://github.com/randombishop"><GitHubIcon /></a>
              <a className="socials-link" href="https://lichess.org/@/randombisbop"><img width="24" height="24" src={LichessIcon} alt="Opensea" /></a>
              <a className="socials-link" href="https://opensea.io/randombishop"><img width="24" height="24" src={OpenseaIcon} alt="Opensea" /></a>
              <a className="socials-link" href="https://randombishop.medium.com/"><img width="24" height="24" src={MediumIcon} alt="Medium" /></a>
            </div>
        )
    }

}

export default Socials;
