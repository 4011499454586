import React from 'react';
import Toolbar from '@mui/material/Toolbar';


import Logo from '../assets/logo.png';


class Header extends React.Component {

    render() {
        return (
            <Toolbar>
                <img src={Logo} alt="logo" />
            </Toolbar>
        )
    }

}

export default Header;
