import React from 'react';


class Intro extends React.Component {

    render() {
        return (
            <div id="intro">
                <div className="title1">Nabil Abdellaoui</div>
                <div className="title2">The Random Bishop</div>
                <div className="text">
                    <p>I fell in love with computers and programming very early, when I was 11 or 12 years old,
                    and I am very lucky that I spent my career working in the field that I enjoy.</p>

                    <p>After finishing Mathematics and Psychology studies, I co-founded a freelance office,
                    and worked on a wide range of software applications, built a SaaS used by a major insurance company in Morocco
                    for processing automobile claims and car accidents' cost analysis,
                    and sold it to a brand of Solera, the global leader in this industry.</p>

                    <p>Then I specialized in Machine Learning, and worked for a couple of startups in the credit space.</p>

                    <p>At Tala, a start-up offering loans for underserved populations, I was in charge of modelling,
                    evaluation, deployment and monitoring of ML models end-to-end;
                    and we scored and offered 5 million customers more than $2 billion in loans.</p>

                    <p>Then in 2021, I discovered the fascinating world of blockchain and decentralized finance,
                    and worked for Gauntlet Network, an amazing R&D team specialized
                    in simulating and understanding the risks in DeFi protocols such as Aave and Compound.</p>

                    <p>Today, I am a proud citizen of the Bitcoin, Ethereum and Polkadot communities,
                    and passionate about building the next generation of decentralized software services in these ecosystems.</p>

                </div>
            </div>
        )
    }

}

export default Intro;
