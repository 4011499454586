import React from 'react';


class Footer extends React.Component {

    render() {
        return (
            <div id="footer">
                <div className="credits">Website artwork provided by the Chain Runners community and @Scruffy669. Thanks for hacking the planet together.</div>
            </div>
        )
    }

}

export default Footer;
