import React from 'react';
import Container from '@mui/material/Container';


import Header from './components/Header';
import Intro from './components/Intro';
import Socials from './components/Socials';
import Footer from './components/Footer';

class App extends React.Component {

    render() {
        return (
            <Container>
                <Header />
                <Intro />
                <Socials/>
                <hr/>
                <Footer />
            </Container>
        )
    }

}

export default App;
